// userContext.js
import { useRouter } from 'next/navigation';
import { createContext, useContext, useState } from 'react';

interface CatalogContextType {
	categoriesSelected: Array<string>;
	priceRange: Array<number>;
	executeSearch: boolean;
	setPriceRange: (values: Array<number>) => void;
	handlerSelected: (categoryId: string) => void;
	setExecuteSearch: (value: boolean) => void;
	clearSelected: () => void;
	clearSelectedAndRouter: () => void;
	setRatings: (rating: Record<number, boolean> | null) => void;
	ratings: Record<number, boolean> | null;
}
const CatalogContext = createContext<CatalogContextType>({
	categoriesSelected: [],
	priceRange: [],
	executeSearch: false,
	ratings: null,
	setPriceRange: () => {},
	handlerSelected: () => {},
	clearSelected: () => {},
	clearSelectedAndRouter: () => {},
	setExecuteSearch: () => {},
	setRatings: () => {},
});

export const CatalogProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [categoriesSelected, setCategoriesSelected] = useState<Array<string>>(
		[]
	);
	const [priceRange, setPriceRange] = useState<Array<number>>([0, 1000]);
	const [executeSearch, setExecuteSearch] = useState<boolean>(false);
	const [ratings, setRatings] = useState<Record<number, boolean> | null>(null);
	const router = useRouter();

	function handlerSelected(value: string) {
		const currentIndex = categoriesSelected.indexOf(value);
		const newChecked = [...categoriesSelected];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setCategoriesSelected(newChecked);
	}
	function clearSelected() {
		setCategoriesSelected([]);
	}
	function clearSelectedAndRouter() {
		setCategoriesSelected([]);
		router.push('/catalog');
	}

	return (
		<CatalogContext.Provider
			value={{
				categoriesSelected,
				priceRange,
				executeSearch,
				ratings,
				setExecuteSearch,
				setRatings,
				handlerSelected,
				clearSelected,
				clearSelectedAndRouter,
				setPriceRange,
			}}
		>
			{children}
		</CatalogContext.Provider>
	);
};

export const useCatalogContext = () => useContext(CatalogContext);

export default CatalogContext;
